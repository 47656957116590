input, button {
  -webkit-appearance: 0;
  -moz-appearance:    0;
  -ms-appearance:     0;
  -o-appearance:      0;
  appearance:         0;
}

.Background {
  display: flex;
  width: 100%;
  height: 100%;
  background: rgb(0,79,41);
  background: linear-gradient(225deg, rgba(0,79,41,1) 0%, rgba(2,45,0,1) 100%);
  * { min-width: 0; }
}

.Decal {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  background: url('/./Assets/Decal.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.Wave {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;

  background: url('/./Assets/Wave.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: right top;
}

.Header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 87%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;


  a { 
    width: 20%; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img { width: 100%; }
  }

  .dolar {
    text-transform: uppercase;
    display: flex;
    flex-flow: column;

    @media screen and (max-width: 1900px) {
      font-size: .8rem;
    }

    P { margin: 0; }

    >p {
      font-size: 1.3rem;
      color: #fff;

      @media screen and (max-width: 1900px) {
        font-size: .8rem;
      }

      span {
        font-size: 1.1rem;
        margin-left: .5rem;

        @media screen and (max-width: 1900px) {
          font-size: .7rem;
          margin-left: .2rem;
        }
      }
    }

    div {
      display: flex;
      flex-flow: row;
      margin: .5rem 0 0;
      width: 100%;

      span {
        background-color: #fff;
        border-radius: .3rem;
        padding: .5rem .7rem;

        &:first-of-type { width: 30%; margin-right: .5rem; }
        width: 100%;

        p { 
          color: #111; 
          line-height: .9rem;
        }
      }
    }
  }

  .timers {
    display: flex;
    flex-flow: row;
    height: 100%;
    width: 30%;
    justify-content: space-between;

    img { 
      height: 60%; 
      width: auto; 
      margin-right: 1rem;
    }

    span {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: #fff;

      p { 
        margin: 0;
      }

      &.hour {
        flex-flow: row;

        .small {
          font-size: 1.15rem;
          text-transform: uppercase;
          margin-top: .5rem;

          @media screen and (max-width: 1900px) {
            font-size: .7rem;
            margin-top: .1rem;
          }
        }
        .large {
          font-weight: 600;
          font-size: 4.8rem;
          line-height: 4rem;
          text-align: center;

          @media screen and (max-width: 1900px) {
            font-size: 3rem;
          }
        }
      }

      &.att {
        min-width: 35%;

        .small {
          font-size: .85rem;
          text-transform: uppercase;
          margin-bottom: .3rem;
          @media screen and (max-width: 1900px) {
            font-size: .7rem;
            margin-bottom: 0;
          }
        }
        .large {
          font-weight: 600;
          font-size: 5rem;
          line-height: 4rem;
          text-align: center;
          @media screen and (max-width: 1900px) {
            font-size: 3rem;
            line-height: 3rem;
          }
        }
      }
    }
  }
}

.Table {
  background-color: rgba(0,0,0,.75);
  border-radius: 1rem;
  width: 98%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 1rem;
  overflow: hidden;

  tbody {
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column;
    align-items: center;

    @keyframes flipA {
      0%   {transform: rotateX(0deg); color: #fff; text-shadow: none;}
      10%   {transform: rotateX(0deg); color: rgba(0, 45, 21, .7);}
      40%  {transform: rotateX(180deg); color: rgba(0, 45, 21, .7);}
      90%  {transform: rotateX(0deg); color: rgba(0, 45, 21, .7); text-shadow: none; }
      100% {transform: rotateX(0deg); color: #fff;}
    }

    @keyframes flipB {
      0%   {transform: rotateX(0deg); color: #fff; text-shadow: none;}
      10%   {transform: rotateX(0deg); color: rgba(0, 45, 21, .7);}
      40%  {transform: rotateX(180deg); color: rgba(0, 45, 21, .7);}
      90%  {transform: rotateX(0deg); color: rgba(0, 45, 21, .7); text-shadow: none;}
      100% {transform: rotateX(0deg); color: #fff;}
    }

    &.flipA td {
      animation-name: flipA;
      animation-duration: 1.5s;
    }

    &.flipB td {
      animation-name: flipB;
      animation-duration: 1.5s;
    }
  }

  tr {
    width: 99%;
    display: flex;
    justify-content: space-around;
    color: #fff;
    text-align: center;
    text-transform: uppercase;

    &.hide { 
      opacity: 0; 
      height: 0;
    }

    @keyframes appear {
      0%   {opacity: .3;}
      50% { opacity: 0; }
      100% {opacity: 1;}
    }

    &.show {
      animation: appear 1s;
    }

    &:first-of-type { 
      width: 99%;
      border-bottom: 2px solid #fff; 
      padding: .5rem 0;
      margin-bottom: .5rem;
    }

    th {
      width: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;

      &:hover button {
        display: flex;
      }

      button {
        display: none;
        appearance: none;
        border: 1px solid #fff;
        border-radius: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        background-color: transparent;
        color: #fff;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        margin-left: 0.5rem;
      }
    }

    td {
      background-color: rgba(0, 45, 21, .7);
      box-shadow: 1px 1px 0px 1px rgba(255,255,255,.5);
      margin: .4rem;
      width: 100%;
      overflow: visible;
      white-space: nowrap;
      height: 2rem;
      padding: 0 0.2rem;

      display: flex;
      align-items: center;
      justify-content: center;

      text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
             1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;

      p {
        font-size: inherit;
        white-space: nowrap;
        width: 100%;
        font-size: 1.1rem;
        line-height: 1rem;
        transform: scaleX(86%);
        letter-spacing: -0.09rem;
        margin: 0;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 1900px) {
        font-size: .7rem;
        padding: .2rem 0;
        margin: .25rem .4rem;
      }

      * {
        transform-style: preserve-3d;        
      }

      &:hover {
        animation-name: flipA;
        animation-duration: 1.5s;      
      }

      &.color1 { 
        color: red; 
        font-weight: 600;
      }
      &.color2 { 
        color: lightgreen; 
        font-weight: 600;
      }
      &.color3 { 
        color: yellow; 
        font-weight: 600;
      }
      &.color4 { 
        color: lightskyblue; 
        font-weight: 600;
      }
      &.color5 { 
        color: orange; 
        font-weight: 600;
      }
    }

  }
}

.pagination {
  margin-top: .5rem;
  margin-bottom: .5rem;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .3rem;
  border-radius: 1rem;

  a {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background-color: #fff;
    flex-shrink: 0;
    margin: 0 .2rem;
    opacity: 1;
    transition: opacity .3s ease;
    cursor: pointer;

    &.hide { opacity: .5; }
  }

  button {
    appearance: none;
    border: 1px solid #fff;
    border-radius: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.1rem;
  }
}

.loginWrapper {
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 2px 20px 5px rgba(0, 0, 0, 0.2);
  padding: 3rem;
  border-radius: 1rem;
  @media screen and (max-width: 997px) {
    max-width: 90%;
  }

  p {
    color: #ff0000;
    font-weight: 600;
    line-height: 0;
    text-align: left;
    margin-right: auto;
  }

  h1 {
    color: #000;
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  input {
    margin-bottom: 10px;
    width: 100%;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid rgb(173, 173, 173);
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    outline: 0;

    &:focus {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 1px solid #000;
    }
  }

  button {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 5px;
    padding: 1rem 0.8rem;
    border-radius: 1rem;
    transition: background-position 0.2s ease 0s;
    background: linear-gradient(to right, rgb(0, 66, 25), rgb(0, 78, 42) 60%) 0% 0% / 150% 100%;
    border: 0;
    text-transform: uppercase;
    cursor: pointer;
    transition: opacity .2s ease;

    &:hover {
      opacity: .9;
    }

    &:active {
      opacity: .7;
    }
  }
}

.fade {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.75);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  z-index: 5;

  .popup {
    display: flex;
    flex-flow: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 2px 20px 5px rgba(0, 0, 0, 0.2);
    padding: 0;
    border-radius: 1rem;
  
    .imgWrapper {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      margin-top: -10%;
      margin-bottom: 3rem;
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 600;

      span {
        @keyframes load {
          0%   {color: #fff;}
          40% {color: #000;}
          90%   {color: #fff;}
          100%   {color: #fff;}
        }
        animation: load 2s infinite;
        &.f { animation-delay: .2s; }
        &.s { animation-delay: .4s; }
      }
    }
  }
}

.Filter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  background-color: rgba(0, 0, 0, 0.85);;
  padding: 1rem 1rem;
  border-radius: 1rem;

  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: flex-end;

  input {
    width: 20vw;
    height: 2vh;
  }

  button {
    appearance: none;
    border: 1px solid #fff;
    border-radius: 1rem;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
  }

  p {
    color: #fff;
    margin: 0 0 .5rem;

    span {
      font-weight: 600;
    }
  }
}